
export default {
  name: 'Landing',
  data () {
    return {
      tab: 'procedures',
      tabs: [
        { label: 'Démarrez vos procédures', value: 'procedures' },
        { label: 'Accédez aux ressources', value: 'resources' },
        { label: 'Documentez les avancées', value: 'frp' }
      ]
    }
  },
  mounted () {
    if (!this.$user.id) {
      this.$tally('n9d42K', {
        max: 2
      })
    }
  }
}
