
import { mdiMagnify } from '@mdi/js'

export default {
  data () {
    return {
      valid: false,
      selectedCollectivite: null,
      searchLoading: false,
      icons: {
        mdiMagnify
      }
    }
  },
  methods: {
    toPublicDashboard () {
      if (!this.valid) {
        this.$refs.form.validate()
      } else {
        const collectiviteId = this.selectedCollectivite.code

        this.$analytics({
          category: 'public',
          name: 'search',
          value: collectiviteId,
          data: {
            collectivite: this.selectedCollectivite
          }
        })

        this.$matomo([
          'trackEvent', 'Socle de PAC', 'Recherche',
          `${this.selectedCollectivite.departement} - ${this.selectedCollectivite.name}`
        ])

        this.$router.push({
          name: 'collectivites-collectiviteId',
          params: {
            collectiviteId: collectiviteId.toString().padStart(5, '0')
          },
          query: {
            isEpci: this.selectedCollectivite.type === 'epci'
          }
        })
      }
    }
  }
}
