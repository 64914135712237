
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isMounted: false
    }
  },
  computed: {
    overlayStyle () {
      const checkedContainer = this.$refs.radioRefs.find(r => r.value === this.value)?.parentElement

      if (!checkedContainer) { return {} }

      return {
        top: `${checkedContainer.offsetTop}px`,
        left: `${checkedContainer.offsetLeft}px`,
        width: `${checkedContainer.offsetWidth}px`,
        height: `${checkedContainer.offsetHeight}px`
      }
    }
  },
  mounted () {
    this.isMounted = true
  }
}
